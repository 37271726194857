<template>
    <div>
        <p class="text-muted text-center section-header fw-bold">
            {{ dayDescription }}
        </p>
        <div v-if="!isExpanded">
            <div class="d-grid">
                <form-button
                    v-for="(timeSlot, index) in timeSlots.slice(0, 3)"
                    :key="index"
                    @click="selectTimeSlot(timeSlot, index)"
                    button-classes="btn btn-outline-primary mb-2 border border-secondary"
                    :submitting="getIsSpinning(index)"
                    event-name="click_button_schedule_selected_time_slot"
                    :data-testid="`scheduling-option-${index}`"
                >
                    <template #label>
                        {{ buttonSubtext }}{{ makeTimeSlotPrettyForButton(timeSlot) }}
                    </template>
                </form-button>
            </div>
            <div class="d-grid">
                <form-button
                    v-show="timeSlots.length > 3"
                    class="btn btn-secondary"
                    @click="toggleIsExpanded"
                    :label="showMoreDescription"
                    event-name="click_toggle_schedule_expand"
                />
            </div>
        </div>
        <div v-else>
            <div class="d-grid">
                <form-button
                    v-for="(timeSlot, index) in timeSlots"
                    :key="index"
                    @click="selectTimeSlot(timeSlot, index)"
                    button-classes="btn btn-outline-primary mb-2 border border-secondary"
                    :submitting="getIsSpinning(index)"
                    event-name="click_button_schedule_selected_time_slot"
                >
                    <template #label>
                        {{ buttonSubtext }}{{ makeTimeSlotPrettyForButton(timeSlot) }}
                    </template>
                </form-button>
            </div>
            <div class="d-grid">
                <form-button
                    class="btn btn-secondary"
                    @click="toggleIsExpanded"
                    :label="showLessDescription"
                    event-name="click_toggle_schedule_collapse"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import DateTime from 'luxon/src/datetime'
    import FormButton from '@/components/base/FormButton'
    import { i18n } from '@/utils/i18n'

    const DayStatus = {
        today: 'today',
        tomorrow: 'tomorrow',
        other: 'other',
    }

    export default {
        name: 'ScheduleOptions',
        components: {
            'form-button': FormButton,
        },
        props: {
            timeSlots: {
                type: Array,
                required: true,
            },
            applicantTimezone: {
                type: String,
                required: true,
            },
            allowSpinner: {
                type: Boolean,
                required: true,
            },
            dayOffset: {
                type: Number,
                required: true,
            },
        },
        data: function () {
            return {
                isExpanded: false,
                dayStatus: null,
                proposedDay: null,
                submittingIndex: null,
            }
        },
        mounted: function () {
            this.setup()
        },
        watch: {
            timeSlots: {
                deep: true,
                handler: function () {
                    this.setup()
                },
            },
        },
        methods: {
            setup: function () {
                this.proposedDay = DateTime.fromJSDate(new Date()).plus({ days: this.dayOffset })
                this.dayStatus = this.getDayStatus()
            },
            getIsSpinning: function (index) {
                return this.allowSpinner && index === this.submittingIndex
            },
            toggleIsExpanded: function () {
                this.isExpanded = !this.isExpanded
            },
            makeTimeSlotPrettyForButton: function (timeSlot) {
                const isoStartDate = DateTime.fromISO(timeSlot.start).setZone(this.applicantTimezone)
                const slotSize = DateTime.fromISO(timeSlot.end).diff(DateTime.fromISO(timeSlot.start)).as('minutes')
                // We deliberately choose to mis-report the slot size so as to incentivize users to complete their session more quickly
                return `${isoStartDate.toFormat('h:mm a (ZZZZ)')} - ${Math.round(slotSize / 2)} Mins`
            },
            selectTimeSlot: function (timeSlot, index) {
                this.submittingIndex = index
                this.$emit('select-time-slot', timeSlot)
            },
            getDayStatus: function () {
                if (this.dayOffset === 0) {
                    return DayStatus.today
                } else if (this.dayOffset === 1) {
                    return DayStatus.tomorrow
                }
                return DayStatus.other
            },
        },
        computed: {
            dayDescription: function () {
                switch (this.dayStatus) {
                    case DayStatus.today:
                        return null
                    case DayStatus.tomorrow:
                        return i18n.t('pages.origination.notaryScheduling.header.tomorrowWithDate', { dateString: this.proposedDay.toFormat('MMM d').toUpperCase() })
                    case DayStatus.other:
                        return this.proposedDay.toFormat('ccc, MMM d').toUpperCase()
                    default:
                        return ''
                }
            },
            buttonSubtext() {
                switch (this.dayStatus) {
                    case DayStatus.today:
                        return null
                    case DayStatus.tomorrow:
                        return i18n.t('pages.origination.notaryScheduling.header.tomorrow') + ', '
                    case DayStatus.other:
                        return this.proposedDay.toFormat('ccc') + ', '
                    default:
                        return ''
                }
            },
            showMoreDescription: function () {
                switch (this.dayStatus) {
                    case DayStatus.today:
                        return i18n.t('pages.origination.notaryScheduling.button.showMoreToday')
                    case DayStatus.tomorrow:
                        return i18n.t('pages.origination.notaryScheduling.button.showMoreTomorrow')
                    case DayStatus.other:
                        return i18n.t('pages.origination.notaryScheduling.button.showMore')
                    default:
                        return ''
                }
            },
            showLessDescription: function () {
                switch (this.dayStatus) {
                    case DayStatus.today:
                        return i18n.t('pages.origination.notaryScheduling.button.showLessToday')
                    case DayStatus.tomorrow:
                        return i18n.t('pages.origination.notaryScheduling.button.showLessTomorrow')
                    case DayStatus.other:
                        return i18n.t('pages.origination.notaryScheduling.button.showLess')
                    default:
                        return ''
                }
            },
        },
    }
</script>

<style lang="scss" scoped></style>
