<template>
    <onboarding-layout
        :error-text="errorText"
        :loading="loading"
        :loading-title="loadingTitle"
    >
        <div
            class="text-center"
            v-if="sorryQueryReason"
        >
            <img
                :src="sorryImageSrc"
                width="112px"
                height="112px"
                alt="Sorry"
            >
        </div>
        <h5
            class="fw-light title my-3"
            v-html="title"
        />
        <p
            v-if="subTitle"
            v-html="subTitle"
            class="text-muted"
        />
        <div v-else>
            <p class="fw-bold mb-0">
                {{ $t('pages.origination.notaryScheduling.contentHeader') }}
            </p>
            <ul class="ps-3">
                <li v-if="!applicantType || [ApplicantType.primary, ApplicantType.coApplicant].includes(applicantType)">
                    {{ $t('pages.origination.notaryScheduling.content1alt') }}
                </li>
                <li>{{ $t('pages.origination.notaryScheduling.content2') }}</li>
                <li>{{ $t('pages.origination.notaryScheduling.content3') }}</li>
            </ul>
        </div>
        <div
            v-if="!loading"
            class="schedule-options-container mt-3"
            data-testid="schedule-options-container"
        >
            <p class="text-center section-header fw-bold">
                Pick a Time
            </p>
            <div v-show="isNotaryImmediatelyAvailable">
                <div class="d-grid">
                    <form-button
                        class="mt-2"
                        @click="confirmTimeSlot(immediateTimeSlot)"
                        :label="$t('pages.origination.notaryScheduling.button.immediateTimeSlot')"
                        data-testid="immediate-notary-schedule-button"
                        event-name="click_button_schedule_selected_immediate_time_slot"
                    />
                </div>
                <div class="d-grid">
                    <div
                        v-show="!isExpanded"
                        id="show-non-immediate-notary-appointments"
                        class="btn btn-tertiary border-0 mt-1"
                        @click="toggleIsExpanded"
                        v-html="$t('pages.origination.notaryScheduling.button.showOther')"
                    />
                </div>
            </div>
            <div
                v-show="!isNotaryImmediatelyAvailable || isExpanded"
                class="mt-2"
            >
                <!-- TODO: Should we display the max days returned by the scheduling API, instead of specifying here? -->
                <template v-for="idx in maxScheduleDays">
                    <schedule-options
                        :key="'schedule-' + idx"
                        :time-slots="getTimeSlotsForDayOffset(idx - 1)"
                        :day-offset="idx - 1"
                        :allow-spinner="true"
                        :applicant-timezone="timezoneData.timeZone"
                        @select-time-slot="confirmTimeSlot"
                    />
                    <br :key="'br-' + idx">
                </template>
            </div>
        </div>
        <template #sidebar>
            <offer-info-card
                v-if="creditOffer"
                title="Your Offer"
                :apr="creditOffer.apr"
                :credit-limit="creditOffer.creditLimit"
            />
            <ul class="list-unstyled list-row">
                <help-list-item />
            </ul>
        </template>
    </onboarding-layout>
</template>
<script>
    import { logger } from '@/utils/logger'
    import OnboardingLayout from '@/layouts/Onboarding'
    import { getNextRoute } from '@/flow/flowController'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { confirmAppointment, getAvailableAppointments, getImmediateTimeSlot, getIsAppointmentAlreadyScheduled, getSessionLink } from '@/services/schedulingApi'
    import ScheduleOptions from '@/components/ScheduleOptions'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { sharedPagePaths } from '@/routes/sharedRoutes'
    import { RouteOption } from '@/flow/flowUtility'
    import { i18n } from '@/utils/i18n'
    import FormButton from '@/components/base/FormButton'
    import HelpListItem from '@/components/onboarding/HelpListItem'
    import OfferInfoCard from '@/components/onboarding/OfferInfoCard'
    import computeCreditOfferFromStorageMixin from '@/mixins/computeCreditOfferFromStorageMixin'
    import groupBy from 'lodash/groupBy'
    import first from 'lodash/first'
    import last from 'lodash/last'
    import { DateTime } from 'luxon'
    import { SchedulingThanksReasons } from '@/utils/thanksPageHelpers'
    import { convertDateTimeFormat } from '@/utils/date'
    import { ApplicantType } from '@/utils/remoteNotarization'
    import { experimentsMixin } from '@/mixins/experimentsMixin'

    const reasonsToImgMap = new Map([
        [SchedulingThanksReasons.tooEarlyToAppointment, require('@/assets/images/origination/TooEarly.svg')],
        [SchedulingThanksReasons.ineligibleAppointment, require('@/assets/images/origination/Calendar.svg')],
        [SchedulingThanksReasons.missedAppointment, require('@/assets/images/origination/Calendar.svg')],
    ])

    export default {
        components: {
            FormButton,
            OnboardingLayout,
            'help-list-item': HelpListItem,
            'offer-info-card': OfferInfoCard,
            'schedule-options': ScheduleOptions,
        },
        mixins: [computeCreditOfferFromStorageMixin, experimentsMixin],
        data: function () {
            return {
                ApplicantType,
                // These only get used sometimes (if we have an error state to show the user upon arrival)
                sorryQueryReason: this.$route.query.reason,
                sorryImageSrc: reasonsToImgMap.get(this.$route.query.reason) || require('@/assets/images/origination/Sad.svg'),
                title: i18n.te(`pages.shared.thanks.reasons.${this.$route.query.reason}.title`)
                    ? i18n.t(`pages.shared.thanks.reasons.${this.$route.query.reason}.title`)
                    : i18n.t('pages.origination.notaryScheduling.title'),

                maxScheduleDays: 14,
                isExpanded: false,
                errorText: '',
                loading: true,
                submitting: false,
                proposedTimeSlots: [],
                timezoneData: {},
                immediateTimeSlot: null,
                applicantType: null,
            }
        },
        mounted: async function () {
            try {
                logger.info('Retrieving available time slots and rendering page.')
                // Get immediate session if available
                const confirmedTimeSlotResponse = await getIsAppointmentAlreadyScheduled()
                const sessionAlreadyInProgress = confirmedTimeSlotResponse.data.payload?.isSessionImmediate

                if (sessionAlreadyInProgress) {
                    // skip to kba
                    logger.info('Skipping to KBA from notary scheduling resulting from backend pull')
                    return await this.$router.push(getNextRoute(this.$router))
                }

                const immediateTimeSlotResponse = await getImmediateTimeSlot()
                this.immediateTimeSlot = immediateTimeSlotResponse.data.payload.immediateTimeSlot

                // Get the rest of the appointments
                const response = await getAvailableAppointments()
                if (!response.data.success) {
                    logger.warn('This user did not qualify to scheduling')
                    return this.$router.push({
                        path: sharedPagePaths.THANKS,
                        query: { reason: SchedulingThanksReasons.ineligibleAppointment },
                    })
                }

                this.applicantType = response.data.payload.applicantType

                this.timezoneData = response.data.payload.applicantTimezoneData || {}
                if (!this.timezoneData.timeZone || typeof this.timezoneData.timeZone !== 'string') {
                    logger.warn('Unknown timezone data for application. Defaulting to America/Los_Angeles')
                    this.timezoneData.timeZone = 'America/Los_Angeles'
                }

                this.proposedTimeSlots = response.data.payload.gatheredTimeSlotArray
                if (!this.proposedTimeSlots || this.proposedTimeSlots.length < 1) {
                    await this.trySetNoAppointmentsAvailableText()
                }

                this.logPageView()
            } catch (error) {
                this.errorText = ApiErrorHandler(error)
                // ApiErrorHandler returns a falsy empty string if it's routing to a new page
                if (this.errorText) {
                    this.logPageView(error.message)
                }
            } finally {
                this.loading = false
            }
        },
        methods: {
            logPageView: function (errorMessage) {
                const allTimeSlots = []
                if (this.immediateTimeSlot) {
                    allTimeSlots.push(this.immediateTimeSlot)
                }
                for (let dayOffset = 0; dayOffset < this.maxScheduleDays; dayOffset++) {
                    if (dayOffset in this.sortedTimeSlotsByDayOffset) {
                        allTimeSlots.push(...this.sortedTimeSlotsByDayOffset[dayOffset])
                    }
                }
                const firstTimeSlot = first(allTimeSlots)
                const lastTimeSlot = last(allTimeSlots)

                this.$logEvent('view_notary_scheduling', {
                    firstTimeSlotIsImmediate: !!this.immediateTimeSlot,
                    firstTimeSlot,
                    lastTimeSlot,
                    allTimeSlots,
                    errorMessage,
                })
            },
            getTimeSlotsForDayOffset: function (dayOffset) {
                return dayOffset in this.sortedTimeSlotsByDayOffset ? this.sortedTimeSlotsByDayOffset[dayOffset] : []
            },
            confirmTimeSlot: async function (timeSlot) {
                if (this.submitting) return

                const isImmediate = timeSlot === this.immediateTimeSlot

                logger.info(`Attempting to schedule session: ${JSON.stringify(timeSlot)}`)
                this.errorText = ''
                this.loading = true
                this.submitting = true

                try {
                    const response = await confirmAppointment(timeSlot, this.timezoneData.timeZone)
                    if (response.data.success) {
                        logger.info('Appointment confirmed successfully.')
                        const confirmedTimeSlot = response.data.payload.confirmedTimeSlot
                        logger.info(`Appointment scheduled for: ${confirmedTimeSlot}`)
                        if (isImmediate) {
                            this.$logEvent('event_scheduled_immediate_notary_timeslot')
                        } else {
                            this.$logEvent('event_scheduled_notary_timeslot', { confirmedTimeSlot })
                        }
                        appSessionStorage.setItem(localStorageKey.confirmedTimeSlot, confirmedTimeSlot)
                        return await this.$router.push(getNextRoute(this.$router, RouteOption.scheduling))
                    } else if (response.data.error === 'EXPERIAN_FROZEN') {
                        logger.info('Experian frozen, refusing to schedule until unfrozen')
                        return await this.$router.replace(getNextRoute(this.$router, RouteOption.experianFrozen))
                    } else {
                        logger.info('Appointment confirmation unsuccessful.')
                        this.proposedTimeSlots = response.data.payload.gatheredTimeSlotArray
                        if (!this.proposedTimeSlots || this.proposedTimeSlots.length < 1) {
                            this.$logEvent('event_notary_timeslot_unavailable', { timeSlot })
                            await this.trySetNoAppointmentsAvailableText()
                            logger.warn('No appointments available for notarization!')
                        } else {
                            if (isImmediate) {
                                this.$logEvent('event_immediate_notary_timeslot_unavailable', { timeSlot, proposedTimeSlots: this.proposedTimeSlots })
                                this.errorText = i18n.t('pages.origination.notaryScheduling.error.immediateAppointmentUnavailableError')
                                this.immediateTimeSlot = null
                            } else {
                                this.$logEvent('event_notary_timeslot_unavailable', {
                                    timeSlot,
                                    proposedTimeSlots: this.proposedTimeSlots,
                                })
                                this.errorText = i18n.t('pages.origination.notaryScheduling.error.timeWasTakenError')
                            }
                            logger.info(`Suggesting other timeslots for notarization: ${this.proposedTimeSlots}`)
                        }
                    }
                } catch (error) {
                    this.errorText = ApiErrorHandler(error)
                } finally {
                    this.loading = false
                    this.submitting = false
                }
            },
            toggleIsExpanded: function () {
                this.$logEvent('click_button_show_more_notary_appointments')
                this.isExpanded = !this.isExpanded
            },
            trySetNoAppointmentsAvailableText: async function () {
                try {
                    const sessionReturnLinkResponse = await getSessionLink()
                    this.errorText = i18n.t('pages.origination.notaryScheduling.error.noUpcomingAppointmentsErrorWithReturnLink', { returnLink: sessionReturnLinkResponse.data.payload })
                } catch (e) {
                    logger.error(`Error getting return link when no notary appointments are available`, null /* event */, e)
                    this.errorText = i18n.t('pages.origination.notaryScheduling.error.noUpcomingAppointmentsError')
                }
            },
        },
        computed: {
            loadingTitle: function () {
                if (this.submitting) {
                    return 'This may take a moment...'
                }
                return 'Loading...'
            },
            /**
             * An Object where the keys are day offsets from today and the values are an ordered
             * list of timeslots.
             */
            sortedTimeSlotsByDayOffset: function () {
                if (this.proposedTimeSlots.length === 0) {
                    return {}
                }
                // First sort the timeslots from earliest to latest
                const sortedTimeslots = [...this.proposedTimeSlots].sort((a, b) => {
                    return a.start < b.start ? -1 : 1
                })
                // Then group the timeslots by their day offset from today
                const startOfToday = DateTime.now().startOf('day')
                return groupBy(sortedTimeslots, (timeSlot) => {
                    const startOfTimeSlotDay = DateTime.fromISO(timeSlot.start).startOf('day')
                    const dayOffset = startOfTimeSlotDay.diff(startOfToday, 'days')
                    return dayOffset.days
                })
            },
            isNotaryImmediatelyAvailable: function () {
                return !!this.immediateTimeSlot
            },
            subTitle: function () {
                //toString required here for editor highlighting to work correctly
                switch (this.sorryQueryReason) {
                    case SchedulingThanksReasons.missedAppointment:
                        return this.isNotaryImmediatelyAvailable
                            ? i18n.t(`pages.shared.thanks.reasons.${this.sorryQueryReason}.subTitleSignNow`)
                            : i18n.t(`pages.shared.thanks.reasons.${this.sorryQueryReason}.subTitle`)
                    case SchedulingThanksReasons.tooEarlyToAppointment:
                        return i18n.t(`pages.shared.thanks.reasons.${this.sorryQueryReason}.subTitle`, {
                            dateTime: convertDateTimeFormat(this.$route.query.startTime, 'Etc/UTC', this.timezoneData.timeZone, 'MMM D [at] h:mm A (z)'),
                            link: this.anchorHref,
                        })
                    default:
                        return ''
                }
            },
        },
    }
</script>
