import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { i18n } from '@/utils/i18n'

// Frontend determines what to do with the experiment_spec.spec_value
// please add a short comment describing the experiment if possible
const ExperimentSpecValue = {
    showNewHardCreditEnquiryCopy: 'showNewHardCreditEnquiryCopy', // will show new hard enquiry copy
}

/**
 * Maps experiment spec_value to a function with any kind of output (strings, boolean, number, [todo: vue components])
 */
const experimentOverridePayload = {
    [ExperimentSpecValue.showNewHardCreditEnquiryCopy]: (isEnabled: boolean = false) =>
        isEnabled ? i18n.t('pages.origination.notaryScheduling.content1alt') : i18n.t('pages.origination.notaryScheduling.content1'),
}

/**
 * Pages can share multiple experiments that we should map to for post-facto analysis
 * This matches up somewhat with originationFlow.ts
 */
const pageExperiment = {
    notarySchedulingPage: {
        [ExperimentSpecValue.showNewHardCreditEnquiryCopy]: experimentOverridePayload[ExperimentSpecValue.showNewHardCreditEnquiryCopy], // disabled, only leaving as example for now
    },
}

/**
 * Returns a map of overrides with enabled values or default values for pages
 */
type ExperimentSpecValueKeys = keyof typeof ExperimentSpecValue
type ExperimentPageKeys = keyof typeof pageExperiment
type EnabledOverrides = { [key in ExperimentPageKeys]: { [key in ExperimentSpecValueKeys]: (boolean & string) | undefined } } // can be extended to enable other types if necessary
export const getEnabledOverrides = (): EnabledOverrides => {
    const enabledOverrides = JSON.parse(appSessionStorage.getItem(localStorageKey.experimentOverrides))

    const pageExperimentKeys = Object.keys(pageExperiment)
    return pageExperimentKeys.reduce((prevType, currType) => {
        // build the enabled experiments per page
        const defaultExperimentsForPage = Object.keys(pageExperiment[currType as keyof typeof pageExperiment]).reduce((prevExp, currExp) => {
            // first set the default values for all experiments
            return {
                ...prevExp,
                [currExp]: pageExperiment[currType as keyof typeof pageExperiment][currExp](),
            }
        }, {})

        const enabledExperimentForPage = Object.keys(enabledOverrides).reduce((prevExp, currExp) => {
            // then set the enabled values per page
            if (pageExperiment[currType as keyof typeof pageExperiment][enabledOverrides[currType]]) {
                // if the currExp is enabled, call function and enable it
                return {
                    ...prevExp,
                    [enabledOverrides[currType]]: pageExperiment[currType as keyof typeof pageExperiment][enabledOverrides[currType]](true),
                }
            }
            return prevExp
        }, {})

        return {
            ...prevType,
            [currType]: { ...defaultExperimentsForPage, ...enabledExperimentForPage },
        }
    }, {}) as EnabledOverrides
}
